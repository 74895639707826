import React, { memo ,useRef,useState} from "react";
import { useMemo } from "react";
import s from "./index.module.less";
const imgbaseUrl = './img/';
// const aa = './img/ama/'
// const ImgDom = () => {
//     return (
//         <img src={imgbaseUrl111} alt="avatar" />
//     )
//   }
const Index = (props) => {
  const {
    iconClass = "",
    name,
    width,
    height,
    style = {},
    imgName = '',
    color = "",
    isHaveHover = false,
    isHaveClick = false,
    onClick = () => { },
    className,
    rotating,
    imgType = 'webp',
  } = props;
  const [hover, setHover] = useState(false);
  const [rotate, setRotate] = useState(false);
  const toggleHover = ()=>{ 
    setHover(!hover)
  }
  const imgSrc = useMemo(()=>{
    if(hover){
      return {
        backgroundImage:`url('${imgbaseUrl}${imgName}_hover.${imgType}')`
      } 
      
    }else{
      return {
        backgroundImage:`url('${imgbaseUrl}${imgName}.${imgType}')`
      } 
      // return `${imgbaseUrl}${imgName}.png`
    }
  },[hover,imgName]);

  const spin = ()=>{
    setRotate(true)
    setTimeout(()=>{
      setRotate(false)
    },1000)
  }
  
  return (
    <span className={`${s["icon-wrapper"]} anticon ${className}`} onClick={(e)=>{e.preventDefault(); onClick(); isHaveClick && spin()}} style={{width: width,height: height}}>
      <span
        className={`${s.customIcon} icon-${name} ${iconClass} ${rotate?s.spln:''} ${rotating?s.spln2:''}`}
        onMouseEnter={isHaveHover?toggleHover:null} 
        onMouseLeave={isHaveHover?toggleHover:null}
        style={{ color: color || "none", width: width,height: height, ...style, ...imgSrc}}
      >
       
      </span>
    </span>
  )
}
export default memo(Index);
