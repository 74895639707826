import React, { useRef, useEffect, memo} from "react";
import s from "./index.module.less";
import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const Index = ()=>{

	const canvasRef = useRef(null);
	
	let canvas = null;
	let context = null;
	let lastFrame = -1;

	const frameCount = 147;
	const currentFrame = index => (
	`https://www.apple.com/105/media/us/airpods-pro/2019/1299e2f5_9206_4470_b28e_08307a42f19b/anim/sequence/large/01-hero-lightpass/${(index + 1).toString().padStart(4, '0')}.jpg`
	);
	
	const images = []
	const airpods = {
		frame: 0
	};
	
	for (let i = 0; i < frameCount; i++) {
		const img = new Image();
		img.src = currentFrame(i);
		images.push(img);
	}
	
	images[0].onload = render;
	
	function render() {
		if (airpods.frame != lastFrame || true){
			if (context){
				context.clearRect(0, 0, canvas.width, canvas.height);
				context.drawImage(images[airpods.frame], 0, 0); 
			}
			console.log('render', airpods);
			lastFrame = airpods.frame;
		}
	}
	
	useGSAP(()=>{
		if (!canvasRef.current) return;
		if (!canvas){
			canvas = canvasRef.current;
			context = canvas.getContext("2d");
			canvas.width = 1158;
			canvas.height = 770;
		}
		gsap.to(airpods, {
			frame: frameCount - 1,
			snap: "frame",
			ease: "none",
			duration: images.length / 60,
			scrollTrigger: {
				scrub: 0.5,
			},
			onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
		});

		console.log('useGSAP');
	}, 
	{scope:canvasRef}
	);

	return(
		<div className={s.root}>
			<canvas ref={canvasRef} className={`${s.lightpass}`}/>
		</div>
	);

}

export default memo(Index);
