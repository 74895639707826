import './App.css';
import React, { useRef, useEffect, memo, useState} from "react";
import s from "./App.module.less";
import CustomIcon from './components/CustomIcon';
import ScrollTriggerTestAlpha from './components/ScrollTriggerTestAlpha';
import AlphaPhoneTimeline from './components/AlphaPhoneTimeline';
import AlphaPhoneVerticalTimeline from './components/AlphaPhoneVerticalTimeline';

function App() {
  const verticalAspect = 1;
  const [verticalMode, setVerticalMode] = useState(window.innerHeight / window.innerWidth > verticalAspect);

  window.addEventListener('resize', function (event) {
    updateMode();
  });

  const updateMode = () =>{
    if (window.innerHeight / window.innerWidth > verticalAspect) {
      setVerticalMode(true);
    } else {
      setVerticalMode(false);
    }
  }

  return (
    <div className="App">
      {/*<div className="h100p">
        <div className={s.wrapWholeBg}>
          <div className={''} style={{zIndex:1}}>
            {<CustomIcon style={{'marginBottom':'10vh'}} imgName={'UI_Button_Start_Light'} onClick={() => {
              console.log('refresh');
              window.location.reload();
            }} width={264} height={123}></CustomIcon>}
          </div>
        </div>
      </div>*/}
      {/*<ScrollTriggerTestAlpha/>*/}
      {
        verticalMode ? 
          <AlphaPhoneVerticalTimeline verticalMode={verticalMode}/>:
          <AlphaPhoneTimeline verticalMode={verticalMode}/>
      }
    </div>
  );
}

export default App;
