const percentageToDecimal = (input) =>{
    if (!input){
        return 0;
    }
    let output = Number(input.replace("%", ""));
    output = output/100;
    return output;
}

const partnerData = [
    {
        "icon": "UI_Button-Logo_AIX",
        "width": 209,
        "height": 64,
        "url": "https://aigentx.xyz/"
    },
    {
        "icon": "UI_Button-Logo_Arena Games",
        "width": 150,
        "height": 64,
        "url": "https://arenavs.com/"
    },
    {
        "icon": "UI_Button-Logo_Ave.ai",
        "width": 150,
        "height": 93,
        "url": "http://ave.ai/"
    },
    {
        "icon": "UI_Button-Logo_BIGA",
        "width": 160,
        "height": 40,
        "url": "https://bigarcade.org/"
    },
    {
        "icon": "UI_Button-Logo_BYTE_CITY",
        "width": 250,
        "height": 80,
        "url": "https://www.byte.city/"
    },
    {
        "icon": "UI_Button-Logo_Carv",
        "width": 261,
        "height": 50,
        "url": "https://carv.io/"
    },
    {
        "icon": "UI_Button-Logo_COQ_INU",
        "width": 150,
        "height": 108,
        "url": "https://coqinu.com/"
    },
    {
        "icon": "UI_Button-Logo_DEW",
        "width": 150,
        "height": 41,
        "url": "https://dew.gg/polygon/collections"
    },
    {
        "icon": "UI_Button-Logo_Element",
        "width": 238,
        "height": 58,
        "url": "https://element.market/"
    },
    {
        "icon": "UI_Button-Logo_FUD_THE_PUG",
        "width": 150,
        "height": 64,
        "url": "https://fudthepug.com/"
    },
    {
        "icon": "UI_Button-Logo_GAIMIN",
        "width": 217,
        "height": 64,
        "url": "https://www.gaimin.io/"
    },
    {
        "icon": "UI_Button-Logo_Galxe",
        "width": 292,
        "height": 53,
        "url": "https://www.galxe.com/"
    },
    {
        "icon": "UI_Button-Logo_Gam3sgg",
        "width": 354,
        "height": 36,
        "url": "https://gam3s.gg/"
    },
    {
        "icon": "UI_Button-Logo_Gameta",
        "width": 296,
        "height": 64,
        "url": "https://www.gameta.pro/#/"
    },
    {
        "icon": "UI_Button-Logo_Hyperspace",
        "width": 313,
        "height": 47,
        "url": "https://hyperspace.xyz/"
    },
    {
        "icon": "UI_Button-Logo_Intract",
        "width": 233,
        "height": 40,
        "url": "https://www.intract.io/"
    },
    {
        "icon": "UI_Button-Logo_Kuroro_Beasts",
        "width": 150,
        "height": 64,
        "url": "https://www.kuroro.com/"
    },
    {
        "icon": "UI_Button-Logo_Magic_Square",
        "width": 150,
        "height": 83,
        "url": "https://magic.store/"
    },
    {
        "icon": "UI_Button-Logo_METAPLEX",
        "width": 356,
        "height": 40,
        "url": "https://www.metaplex.com/"
    },
    {
        "icon": "UI_Button-Logo_NFPrompt",
        "width": 313,
        "height": 52,
        "url": "https://nfprompt.io/"
    },
    {
        "icon": "UI_Button-Logo_NFTDaily",
        "width": 150,
        "height": 81,
        "url": "https://thenftdaily.co.uk/"
    },
    {
        "icon": "UI_Button-Logo_Polygon",
        "width": 253,
        "height": 64,
        "url": "https://polygon.technology/"
    },
    {
        "icon": "UI_Button-Logo_Pudgy_Penguins",
        "width": 180,
        "height": 70,
        "url": "https://www.pudgypenguins.com/"
    },
    {
        "icon": "UI_Button-Logo_QuickSwap",
        "width": 335,
        "height": 103,
        "url": "https://quickswap.exchange/#/"
    },
    {
        "icon": "UI_Button-Logo_SUPRA",
        "width": 233,
        "height": 50,
        "url": "https://supra.com/"
    },
    {
        "icon": "UI_Button-Logo_TaskOn",
        "width": 150,
        "height": 81,
        "url": "https://taskon.xyz/"
    },
    {
        "icon": "UI_Button-Logo_The_Pixels",
        "width": 150,
        "height": 81,
        "url": "https://t.me/the_pixels_bot"
    },
    {
        "icon": "UI_Button-Logo_TON",
        "width": 168,
        "height": 64,
        "url": "https://ton.org/"
    },
    {
        "icon": "UI_Button-Logo_ULTIVERSE",
        "width": 335,
        "height": 128,
        "url": "https://ultiverse.io/"
    },
    {
        "icon": "UI_Button-Logo_UniSat",
        "width": 244,
        "height": 51,
        "url": "https://unisat.io/"
    },
    {
        "icon": "UI_Button-Logo_Waypoint",
        "width": 150,
        "height": 81,
        "url": "https://discord.com/invite/waypointgaming"
    },
    {
        "icon": "UI_Button-Logo_Wormhole",
        "width": 462,
        "height": 72,
        "url": "https://wormhole.com/"
    },
    {
        "icon": "UI_Button-Logo_Zen_Academy",
        "width": 150,
        "height": 108,
        "url": "https://discord.com/invite/zenacademy"
    },
    {
        "icon": "UI_Button-Logo_ZetaChain",
        "width": 284,
        "height": 53,
        "url": "https://www.zetachain.com/"
    }
]

export {
    percentageToDecimal,
    partnerData,
}